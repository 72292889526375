import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import _get from 'lodash/get';
import GalleryPagination from './GalleryPagination';
import { disableFilterAnalytics, getProductsPerPage, getURLParameterBykey } from '../../utils';

import ScrollRendererWrapper from '../ScrollRendererWrapper/ScrollRendererWrapper';

export const LoadPagination = (props) => {
    const { containerTheme, deviceType, onPageChange } = props;

    const pagination = useSelector((state) => state.pagination.pagination);
    const featureFlags = useSelector((state) => state.context.featureFlags);
    const marketingTilesInitialCount =
        useSelector((state) => state.irisContent.coreZone.marketingTilesInitialCount) +
        useSelector((state) => state?.productList?.sponsouredRenderedList || 0);
    const productsPerPage = getProductsPerPage(useSelector((state) => state));
    const enableMktBasedTotalPage = useSelector((state) =>
        _get(state, 'context.featureFlags.enableMktBasedTotalPage', true)
    );

    /* istanbul ignore next */
    const onPageChangeHandler = useCallback(
        (pageNumber) => {
            disableFilterAnalytics();
            let mktTiles;
            if (
                !featureFlags.disableOrphanProducts &&
                pageNumber > 1 &&
                marketingTilesInitialCount > 0
            ) {
                mktTiles = marketingTilesInitialCount;
            }

            onPageChange(
                pageNumber > 1 ? pageNumber : null,
                mktTiles,
                marketingTilesInitialCount > 0
            );
        },
        [onPageChange, featureFlags, marketingTilesInitialCount]
    );
    const getMarketingTilesInitialCount = () => {
        let mkTiles;
        if (!featureFlags.disableOrphanProducts) {
            mkTiles =
                marketingTilesInitialCount >= 0
                    ? marketingTilesInitialCount
                    : getURLParameterBykey('mktTiles');
        }
        return mkTiles || 0;
    };

    /* istanbul ignore next */
    const getTotalPaginationPage = (pagination) => {
        const marketingTiles = getMarketingTilesInitialCount();
        if (pagination && marketingTiles > 0) {
            const {
                productsPerPage,
                totalPages,
                selectedPageNumber,
                totalProductsCount,
            } = pagination;
            if (totalPages < 25 && selectedPageNumber === 1) {
                const paginationTotalPages = Math.ceil(
                    (totalProductsCount + marketingTiles) / productsPerPage
                );
                const modifyPagination = { ...pagination };
                modifyPagination.totalPages = paginationTotalPages;

                return modifyPagination;
            }

            return pagination;
        }
        return pagination;
    };

    const fetchPaginationObj = (pagination) => {
        const paginationObj = enableMktBasedTotalPage
            ? getTotalPaginationPage(pagination)
            : pagination;
        return paginationObj;
    };

    if (_get(pagination, 'totalProductsCount') > 0 && !__SERVER__) {
        return (
            <ScrollRendererWrapper>
                <GalleryPagination
                    {...fetchPaginationObj(pagination)}
                    {...containerTheme}
                    onPageChange={onPageChangeHandler}
                    marketingTilesInitialCount={getMarketingTilesInitialCount()}
                    deviceType={deviceType}
                    productsPerPage={productsPerPage}
                />
            </ScrollRendererWrapper>
        );
    }

    return <div />;
};

LoadPagination.propTypes = {
    containerTheme: PropTypes.string,
    deviceType: PropTypes.object,
    onPageChange: PropTypes.func,
};

LoadPagination.defaultProps = {
    containerTheme: null,
    deviceType: {},
    onPageChange: null,
};

export default withRouter(LoadPagination);
